import Vue from 'vue'
import Router from 'vue-router'
// import Website from '@/views/website/index'
const Website = () => import('@/views/newWebsite/index.vue')
const Tiya = () => import('@/views/newWebsite/tiya.vue')
const TiyaJob = () => import('@/views/newWebsite/tiyaJob.vue')
const Vico = () => import('@/views/newWebsite/vico.vue')
const Course = () => import('@/views/newWebsite/course.vue')
const Man = () => import('@/views/newWebsite/man.vue')
import Play from '@/views/play/index'
import Agreement from '@/views/agreement'
import Privacy from '@/views/privacy'
import Copyright from '@/views/copyright'

import config from '../configCenter.json'

Vue.use(Router)

const templateMap = {
  tiya: Tiya,
  vico: Vico,
  tiyaJob: TiyaJob,
  course: Course,
  man: Man
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Website',
      component: templateMap[config.template] || Website
    },
    {
      path: '/play',
      name: 'Play',
      component: Play
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: Agreement
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/copyright',
      name: 'Copyright',
      component: Copyright
    }
  ]
})
